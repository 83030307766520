'use client';

import ContentInfoForm from '@/components/forms/creators/ContentInfoForm';
import { useUploadQueue } from '@/hooks/useUploadQueue';
import { useRouter } from '@/i18n/routing';
import { useUser } from '@/stores/user.store';
import { useQueryClient } from '@tanstack/react-query';
import { useLockBodyScroll } from '@uidotdev/usehooks';
import { motion } from 'framer-motion';
import { useTranslations } from 'next-intl';
import { FC, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiChevronLeft } from 'react-icons/fi';
import { useUploadStore } from '@/stores/upload-status.store';
type UploadContentV2Props = {
  onClose: () => void;
};
const UploadContentV2: FC<UploadContentV2Props> = ({
  onClose
}) => {
  const router = useRouter();
  const user = useUser();
  useLockBodyScroll();
  const queryClient = useQueryClient();
  const {
    addToUploadQueue,
    uploadNext
  } = useUploadQueue();
  const {
    isUploading,
    uploadingQueueSize
  } = useUploadStore();
  const t = useTranslations('common');
  const [file, setFile] = useState<File | null>(null);
  const {
    getInputProps,
    getRootProps
  } = useDropzone({
    onDrop: acceptedFiles => {
      console.log('Accepted files: ', acceptedFiles);
      setFile(acceptedFiles[0]);
    }
  });
  useEffect(() => {
    if (!isUploading && uploadingQueueSize > 0) {
      uploadNext();
    }
  }, [isUploading, uploadingQueueSize, uploadNext]);
  function handleUpload(data: {
    text: string;
    exclusive: boolean;
    colabWith?: string | null;
    exclusivePrice?: number;
  }) {
    if (file) {
      addToUploadQueue({
        file,
        data
      });
      setFile(null);
      onClose();
      if (!isUploading) {
        uploadNext();
      }
    } else {
      console.log('file not selected');
    }
  }
  return <motion.div initial={{
    opacity: 0,
    y: '100%'
  }} animate={{
    opacity: 1,
    y: '0%'
  }} exit={{
    opacity: 0,
    y: '100%'
  }} className="fixed inset-0 !z-[9020] h-dvh w-dvw overflow-y-auto bg-background px-3 pb-24 pt-5" data-sentry-element="unknown" data-sentry-component="UploadContentV2" data-sentry-source-file="UploadContentV2.tsx">
            <div className="mb-5 grid grid-cols-[1fr,8fr,1fr] content-center items-center">
                <div>
                    <FiChevronLeft className="size-6" onClick={onClose} data-sentry-element="FiChevronLeft" data-sentry-source-file="UploadContentV2.tsx" />
                </div>
                <h4 className="text-center font-bold uppercase tracking-wider">Nahrání obsahu</h4>
                <div></div>
            </div>
            <div className="mb-5">
                {file ? <div className="flex h-[50dvh] items-center justify-center">
                        {file.type.includes('image') ? <img src={URL.createObjectURL(file)} className="h-full w-auto" alt="" /> : <video playsInline className="h-full w-auto" src={URL.createObjectURL(file)} controls />}
                    </div> : <div {...getRootProps()} className="flex items-center justify-center border-2 border-dashed p-8 py-14 text-center">
                        <input {...getInputProps()} />
                        <p>{t('uploadDrag')}</p>
                    </div>}
            </div>
            <ContentInfoForm creators={[]} onSubmit={handleUpload} priceError={''} setPriceError={() => {}} contentImg={''} data-sentry-element="ContentInfoForm" data-sentry-source-file="UploadContentV2.tsx" />
        </motion.div>;
};
export default UploadContentV2;