'use client';

import { signOut, switchToProfile } from '@/actions/auth.actions';
import { getConnectedAccounts } from '@/actions/user.actions';
import { Role, User } from '@/db/schema';
import { getProfilePicture } from '@/lib/utils';
import { useQuery } from '@tanstack/react-query';
import { useClickAway } from '@uidotdev/usehooks';
import { motion } from 'framer-motion';
import { Settings, Shield, User as UserIcon } from 'lucide-react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useState } from 'react';
import { FiDollarSign, FiLoader, FiLogOut } from 'react-icons/fi';
import { LuCrown } from 'react-icons/lu';
import { Avatar, AvatarImage } from '../ui/avatar';
import { BButton } from '../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Separator } from '../ui/separator';
import React from 'react';
type ProfileDrawerProps = {
  user: User;
  isOpen: boolean;
  onClose: () => void;
};
const ProfileDrawer = ({
  user,
  isOpen,
  onClose
}: ProfileDrawerProps) => {
  const t = useTranslations('menu');
  const {
    data: connectedAcc,
    isLoading
  } = useQuery({
    queryKey: ['connected_acc'],
    queryFn: () => getConnectedAccounts(user.id),
    initialData: []
  });
  const ref = useClickAway<HTMLDivElement>(() => console.log('first'));
  const [isLogingOut, setIsLogingOut] = useState(false);
  return <motion.div ref={ref} initial={{
    opacity: 0,
    y: '100%'
  }} animate={{
    opacity: 1,
    y: 0
  }} exit={{
    opacity: 0,
    y: '100%'
  }} className="absolute bottom-20 right-0 z-[999] flex w-full flex-col justify-center gap-4 overflow-hidden rounded-t-sm border-t border-border bg-background px-6 py-2 text-xl font-thin md:w-96" data-sentry-element="unknown" data-sentry-component="ProfileDrawer" data-sentry-source-file="ProfileDrawer.tsx">
            <>
                <div className="flex place-items-center justify-between">
                    <Link href="/contact" className="flex flex-col items-center text-center" data-sentry-element="Link" data-sentry-source-file="ProfileDrawer.tsx">
                        <span className="mx-auto w-fit text-nowrap px-2 py-0.5 text-sm font-semibold">BETA - v1.2</span>
                        <span className="text-nowrap text-xs font-semibold text-blue-500">Nahlásit problém</span>
                    </Link>
                    <Popover data-sentry-element="Popover" data-sentry-source-file="ProfileDrawer.tsx">
                        <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="ProfileDrawer.tsx">
                            <div className="flex w-full items-center justify-end">
                                <div className="flex flex-col items-end">
                                    <span className="font-bold tracking-wider">{user.username}</span>
                                    <span className="text-sm text-muted-foreground">({user.email})</span>
                                </div>
                            </div>
                        </PopoverTrigger>
                        <PopoverContent className="flex w-fit items-center gap-2" align="end" side="top" alignOffset={-25} data-sentry-element="PopoverContent" data-sentry-source-file="ProfileDrawer.tsx">
                            {connectedAcc.map(profile => <div key={profile.id} className="flex flex-col items-center" onClick={async e => {
              await switchToProfile(profile.id);
              window.location.reload();
            }}>
                                    <Avatar className="size-8 cursor-pointer">
                                        <AvatarImage src={getProfilePicture(profile.username, profile.image)} />
                                    </Avatar>
                                    <span className="text-xs">{profile.username}</span>
                                </div>)}
                            <div className="flex flex-col items-center" onClick={async () => {
              setIsLogingOut(true);
              await signOut();
              setIsLogingOut(false);
            }}>
                                <BButton size="icon" className="flex size-8 flex-col items-center rounded-full" data-sentry-element="BButton" data-sentry-source-file="ProfileDrawer.tsx">
                                    {isLogingOut ? <FiLoader className="animate-spin" /> : <FiLogOut />}
                                </BButton>
                                <span className="text-xs">{t('logout')}</span>
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>
            </>

            <Separator data-sentry-element="Separator" data-sentry-source-file="ProfileDrawer.tsx" />
            {user.role.includes(Role.Admin) && <>
                    <Link href={'/admin'} className="flex items-center gap-2 rounded-md p-2 font-thin uppercase tracking-wider transition-colors hover:bg-white/20">
                        <Shield size={24} />
                        {t('admin')}
                    </Link>
                    <Separator />
                </>}
            {user.role.includes(Role.Creator) && <>
                    <Link href={'/finance'} className="flex items-center gap-2 rounded-md p-2 font-thin uppercase tracking-wider transition-colors hover:bg-white/20">
                        <FiDollarSign size={24} />
                        {t('finance')}
                    </Link>
                    <Link href={'/top-subs'} className="flex items-center gap-2 rounded-md p-2 font-thin uppercase tracking-wider transition-colors hover:bg-white/20">
                        <LuCrown size={24} />
                        {t('topSubs')}
                    </Link>
                    <Separator />
                </>}
            <Link href={'/profile'} onClick={() => onClose()} className="flex items-center gap-2 rounded-md p-2 font-thin uppercase tracking-wider transition-colors hover:bg-white/20" data-sentry-element="Link" data-sentry-source-file="ProfileDrawer.tsx">
                <UserIcon size={24} data-sentry-element="UserIcon" data-sentry-source-file="ProfileDrawer.tsx" />
                {t('profile')}
            </Link>
            <Link href={'/settings/general'} onClick={() => onClose()} className="flex items-center gap-2 rounded-md p-2 font-thin uppercase tracking-wider transition-colors hover:bg-white/20" data-sentry-element="Link" data-sentry-source-file="ProfileDrawer.tsx">
                <Settings size={24} data-sentry-element="Settings" data-sentry-source-file="ProfileDrawer.tsx" />
                {t('settings')}
            </Link>
        </motion.div>;
};
export default ProfileDrawer;