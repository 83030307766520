'use client';

import { enabledNotifications } from '@/actions/user.actions';
import SubscribeToNotifications from '@/app/[locale]/(private)/notifications/components/SubscribeToNotifications';
import { useQuery } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
interface DetectionResult {
  isPWA: boolean;
  platform: 'iOS' | 'Android' | 'Desktop' | 'Unknown';
  browser: 'Chrome' | 'Safari' | 'Other';
}
function detectEnvironment(): DetectionResult {
  if (typeof window === 'undefined') {
    return {
      isPWA: false,
      platform: 'Unknown',
      browser: 'Other'
    };
  }
  const userAgent = window.navigator.userAgent.toLowerCase();

  // Detekce PWA
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  const isFullscreen = window.matchMedia('(display-mode: fullscreen)').matches;
  const isIOSPWA = 'standalone' in window.navigator && (window.navigator as any).standalone;
  const isPWA = isStandalone || isFullscreen || isIOSPWA;

  // Detekce platformy
  let platform: DetectionResult['platform'] = 'Unknown';
  if (/iphone|ipad|ipod/.test(userAgent)) {
    platform = 'iOS';
  } else if (/android/.test(userAgent)) {
    platform = 'Android';
  } else if (/win|mac|linux/.test(userAgent)) {
    platform = 'Desktop';
  }

  // Detekce prohlížeče (pouze Chrome a Safari)
  let browser: DetectionResult['browser'] = 'Other';
  if (/chrome/.test(userAgent) && !/edg|opr/.test(userAgent)) {
    browser = 'Chrome';
  } else if (/safari/.test(userAgent) && !/chrome|edg|opr/.test(userAgent)) {
    browser = 'Safari';
  }
  return {
    isPWA,
    platform,
    browser
  };
}
type NotificationBannerProps = {
  username: string;
};
const NotificationBanner: FC<NotificationBannerProps> = ({
  username
}) => {
  const [env, setEnv] = useState<DetectionResult>({
    isPWA: false,
    platform: 'Unknown',
    browser: 'Other'
  });
  const {
    data: isEnabledNotifications
  } = useQuery({
    queryKey: ['notificationEnabled'],
    queryFn: () => enabledNotifications()
  });
  useEffect(() => {
    setEnv(detectEnvironment());
  }, []);
  if (isEnabledNotifications) return null;
  return <div className="-mx-4 bg-red-500 py-2 pt-3 text-center tracking-wider text-white" data-sentry-component="NotificationBanner" data-sentry-source-file="NotificationEnable.banner.tsx">
            <p>
                Ahoj <strong>{username}</strong>, nemáš zapnuté notifikace.
            </p>
            <div className="mx-5">
                <SubscribeToNotifications data-sentry-element="SubscribeToNotifications" data-sentry-source-file="NotificationEnable.banner.tsx" />
            </div>
        </div>;
};
export default NotificationBanner;