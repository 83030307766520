'use client';

import { pb, PbUser, subscribe, subscriptions, unsubscribe } from '@/lib/pb';
import { useOnlineUsers } from '@/stores/onlineUsers.store';
import { differenceInMinutes, format } from 'date-fns';
import { User } from 'lucia';
import { ReactNode, useEffect, useRef } from 'react';
const INACTIVE_INTERVAL = 1000 * 60 * 5; // 5 minutes
const INACTIVE_TIME = 5; // in minutes

const OnlineProvider = ({
  user,
  children
}: {
  user: User;
  children: ReactNode;
}) => {
  const {
    addOnlineUser,
    removeOnlineUser,
    onlineUsers,
    setOnlineUsers,
    isUserOnline
  } = useOnlineUsers();
  const lastActivityTime = useRef(Date.now());
  const updateOnlineStatus = async (status: boolean) => {
    if (status) {
      addOnlineUser(user.id, format(Date.now(), 'yyyy-MM-dd HH:mm:ss'));
    } else {
      removeOnlineUser(user.id);
    }
    const now = new Date().toISOString();
    try {
      const res = await pb.collection('users').update(user.id, {
        isOnline: status,
        lastSeen: now
      });
    } catch (error) {
      console.error('Failed to update online status:', error);
    }
  };
  useEffect(() => {
    pb.collection('users').getFullList({
      filter: `isOnline=true`
    }).then(d => {
      setOnlineUsers(d.map(r => ({
        id: r.id,
        lastSeen: r.lastSeen
      })));
    });
    if (!subscriptions.has('users')) {
      subscribe<PbUser>('users', e => {
        if (e.action === 'update') {
          if (e.record.isOnline) {
            addOnlineUser(e.record.id, e.record.lastSeen);
          } else {
            removeOnlineUser(e.record.id);
          }
        }
      });
    }
    const checkActivity = () => {
      const diff = differenceInMinutes(Date.now(), lastActivityTime.current);
      if (diff >= INACTIVE_TIME) {
        if (isUserOnline(user.id)) {
          updateOnlineStatus(false);
        }
      } else if (!isUserOnline(user.id)) {
        updateOnlineStatus(true);
      }
    };
    const handleActivity = () => {
      lastActivityTime.current = Date.now();
      if (!isUserOnline(user.id)) {
        updateOnlineStatus(true);
      }
    };
    const activityInterval = setInterval(checkActivity, INACTIVE_INTERVAL);
    ['mousedown', 'keydown', 'touchstart', 'scroll'].forEach(event => {
      document.addEventListener(event, handleActivity);
    });
    window.addEventListener('focus', handleActivity);
    updateOnlineStatus(navigator.onLine);
    return () => {
      clearInterval(activityInterval);
      updateOnlineStatus(false);
      ['mousedown', 'keydown', 'touchstart', 'scroll'].forEach(event => {
        document.removeEventListener(event, handleActivity);
      });
      window.removeEventListener('focus', handleActivity);
      unsubscribe('users');
    };
  }, []);
  return <>{children}</>;
};
export default OnlineProvider;