import { PbNotification } from '@/lib/pb';
import { INotification } from '@/types/models/notification';
import { create } from 'zustand';

type NotificationState = {
    unreadCount: number;
    resetCount: () => void;
    removeOne: () => void;
    addOne: () => void;
};

export const useOznameni = create<NotificationState>(set => ({
    unreadCount: 0,
    resetCount: () => set(() => ({ unreadCount: 0 })),
    addOne: () => set(s => ({ unreadCount: s.unreadCount > 0 ? s.unreadCount + 1 : 1 })),
    removeOne: () => set(s => ({ unreadCount: s.unreadCount > 0 ? s.unreadCount - 1 : 0 }))
}));
