import { PbChat } from '@/lib/pb';
import dayjs from 'dayjs';
import { create } from 'zustand';

interface ConversationState {
    chats: PbChat[];
    updateChat: (chat: PbChat) => boolean;
    set: (chats: PbChat[]) => void;
    add: (chat: PbChat[]) => void;
    remove: (id: string) => void;
    girlLastGMessage: string;
    userLastGMessage: string;
    setGirlLastGMessage: (message: string) => void;
    setUserLastGMessage: (message: string) => void;
}
export const useChatStore = create<ConversationState>()(set => ({
    girlLastGMessage: '',
    userLastGMessage: '',
    setGirlLastGMessage: message => {
        set({ girlLastGMessage: message });
    },
    setUserLastGMessage: message => {
        set({ userLastGMessage: message });
    },
    chats: [],
    set: newChats => set(() => ({ chats: newChats })),
    updateChat: chat => {
        let lastMessageChanged = false;
        set(state => ({
            chats: state.chats
                .map(c => {
                    if (c.id === chat.id) {
                        lastMessageChanged = c.lastMessage !== chat.lastMessage;
                        return { ...c, ...chat };
                    }
                    return c;
                })
                .sort((a, b) => dayjs(b.updated).valueOf() - dayjs(a.updated).valueOf())
        }));

        return lastMessageChanged;
    },
    add: chats => set(state => ({ chats: [...state.chats, ...chats] })),
    remove: id => set(state => ({ chats: state.chats.filter(c => c.id !== id) }))
}));

type NotificationState = {
    notification: number;
    unreadGlobal: boolean;
    unreadChats: {
        count: number;
        from: string[];
    };
    setUnreadGChat: (unread: boolean) => void;
    addUnreadChat: (from: string) => void;
    removeUnreadChat: (id: string) => void;
    clear: () => void;
};
export const useNotifications = create<NotificationState>()(set => ({
    notification: 0,
    unreadGlobal: false,
    unreadChats: {
        count: 0,
        from: []
    },
    setUnreadGChat: unread => set(() => ({ unreadGlobal: unread })),
    addUnreadChat: id => set(state => ({ unreadChats: { count: state.unreadChats.count + 1, from: [...state.unreadChats.from.filter(from => from !== id), id] } })),
    removeUnreadChat: id =>
        set(state => ({ unreadChats: { count: state.unreadChats.from.filter(from => from !== id).length, from: state.unreadChats.from.filter(from => from !== id) } })),
    clear: () => set(state => ({ unreadChats: { count: 0, from: [] } }))
}));
