'use client';

import React, { useEffect, useState } from 'react';
import { useUploadStore } from '@/stores/upload-status.store';
import { motion, AnimatePresence } from 'framer-motion';
import { LuLoader2 } from 'react-icons/lu';
export const UploadStatus: React.FC = () => {
  const {
    isUploading,
    uploadingQueueSize,
    uploadingType
  } = useUploadStore();
  const [showCompleted, setShowCompleted] = useState(false);
  useEffect(() => {
    if (!isUploading && uploadingQueueSize === 0) {
      setShowCompleted(true);
      const timer = setTimeout(() => setShowCompleted(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [isUploading, uploadingQueueSize]);

  // if (!isUploading && uploadingQueueSize === 0 && !showCompleted) return null;

  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="UploadStatus" data-sentry-source-file="UploadStatus.tsx">
            {isUploading && uploadingQueueSize > 0 && <motion.div initial={{
      opacity: 0,
      y: 50
    }} animate={{
      opacity: 1,
      y: 0
    }} exit={{
      opacity: 0,
      y: 50
    }} className={`fixed inset-x-0 top-0 z-ultr px-2 py-1 shadow-lg ${showCompleted ? 'bg-green-500' : 'bg-primary'} text-primary-foreground`}>
                    {showCompleted ? <p className="text-center font-bold text-white">Hotovo</p> : <div className="flex items-center justify-center gap-2 text-white">
                            <span className="flex items-center justify-center gap-1">
                                <LuLoader2 className="size-5 animate-spin" />
                                <p>Nahrávání obsahu</p>
                            </span>
                            <p className="font-bold">{`(${uploadingQueueSize})`}</p>
                        </div>}
                </motion.div>}
        </AnimatePresence>;
};