'use client';

import { signOut } from '@/actions/auth.actions';
import { getUserCoins } from '@/actions/user.actions';
import { useTopUpOpen } from '@/stores/topup.open.store';
import { cn } from '@/lib/utils';
import { useQuery } from '@tanstack/react-query';
import { useLongPress } from '@uidotdev/usehooks';
import React from 'react';
import { LuCoins, LuLoader2 } from 'react-icons/lu';
const CoinsCount: React.FC<{
  isCreator?: boolean;
  className?: string;
}> = ({
  isCreator = false,
  className
}) => {
  const openTopUp = useTopUpOpen(state => state.setOpen);
  const {
    data: coins,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: ['coins'],
    queryFn: async () => await getUserCoins(),
    staleTime: 0,
    refetchOnWindowFocus: true
  });
  const longPressEvent = useLongPress(async () => {
    await signOut();
    location.reload();
  }, {
    threshold: 500
  });
  return <div id="coins" className={cn('flex items-center justify-center gap-1 text-primary', isCreator && 'text-primary', className)} {...longPressEvent} onClick={() => openTopUp(true)} data-sentry-component="CoinsCount" data-sentry-source-file="CoinsCount.tsx">
            <span className="text-muted-foreground">{isLoading || isFetching ? <LuLoader2 className="animate-spin" /> : Intl.NumberFormat('cs-CZ').format(coins ?? 0)}</span>
            {isCreator ? 'Kč' : <LuCoins size={16} />}
        </div>;
};
export default CoinsCount;