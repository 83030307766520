'use client';

import React, { PropsWithChildren, useEffect } from 'react';
import { useCoins } from '@/stores/coins';
import { Role, User } from '@/db/schema';
import { useUser } from '@/stores/user.store';
const InitState: React.FC<{
  user: User;
} & PropsWithChildren> = ({
  children,
  user
}) => {
  const {
    setCoins
  } = useCoins();
  const setUserInfo = useUser(state => state.setUserInfo);
  useEffect(() => {
    setCoins(user.coins);
    setUserInfo(user);
  }, []);
  return <>{children}</>;
};
export default InitState;