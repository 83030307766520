import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.23.5_next@14.2.16_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_re_vfoc2ly5aqft6isbm5xqyyml3i/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.23.5_next@14.2.16_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_re_vfoc2ly5aqft6isbm5xqyyml3i/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/node_modules/.pnpm/next-themes@0.3.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.16_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(private)/InitState.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AkLogo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/bottomNav/BottomNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/OnlineProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/CoinsCount.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/LangSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/NotificationEnable.banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/PwaInstall.banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UploadStatus"] */ "/app/src/components/shared/UploadStatus.tsx");
