'use client';

import { enabledNotifications } from '@/actions/user.actions';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { Switch } from '@/components/ui/switch';
import { useToast } from '@/components/ui/use-toast';
import { useRouter } from '@/i18n/routing';
import { isSubscribedToPushNotifications, registerServiceWorker, subscribeToPushNotifications, unsubscribeFromPushNotifications } from '@/lib/notifications';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslations } from 'next-intl';
import React, { useEffect } from 'react';
const SubscribeToNotifications = () => {
  const queryClient = useQueryClient();
  const t = useTranslations();
  const router = useRouter();
  const {
    toast
  } = useToast();
  const [isSubscribed, setIsSubscribed] = React.useState(false);
  useEffect(() => {
    isSubscribedToPushNotifications().then(res => {
      setIsSubscribed(res ?? false);
    });
    async function setupServiceWorker() {
      try {
        await registerServiceWorker();
        console.log('Service worker registered');
      } catch (error) {
        console.error('Service worker registration failed', error);
      }
    }
    setupServiceWorker();
  }, []);
  const handleNotification = async (checked: boolean) => {
    if (checked) {
      const result = await Notification.requestPermission();
      if (result === 'granted') {
        await subscribeToPushNotifications();
        setIsSubscribed(true);
        toast({
          title: t('toast.notifications.success.title'),
          description: t('toast.notifications.success.description'),
          variant: 'success'
        });
        queryClient.invalidateQueries({
          queryKey: ['notificationEnabled']
        });
      } else {
        toast({
          title: t('toast.notifications.error.title'),
          description: t('toast.notifications.error.description'),
          variant: 'info'
        });
        return;
      }
    } else {
      await unsubscribeFromPushNotifications();
      setIsSubscribed(false);
    }
  };
  return <>
            <div className="flex w-full items-center justify-between py-5">
                <Label className={isSubscribed ? 'font-bold' : 'font-medium'} data-sentry-element="Label" data-sentry-source-file="SubscribeToNotifications.tsx">{t('settings.notifications')}</Label>
                <Switch checked={isSubscribed} onCheckedChange={handleNotification} data-sentry-element="Switch" data-sentry-source-file="SubscribeToNotifications.tsx" />
            </div>
        </>;
};
export default SubscribeToNotifications;