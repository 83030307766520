'use client';

import { Toaster } from '@/components/ui/toaster';
import { useTranslations } from 'next-intl';
import { useRouter, useSearchParams } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import QueryProvider from './QueryProvider';
import Onboarding from './shared/Onboarding';
import * as Sentry from '@sentry/nextjs';
import { useUser } from '@/stores/user.store';
const Providers = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const t = useTranslations('tutorial');
  const searchParams = useSearchParams();
  const showTutorialParam = searchParams.get('showTutorial') === 'true';
  const router = useRouter();
  const redirectToGirl = searchParams.get('redirectToGirl');
  const user = useUser();
  const [showTutorial, setShowTutorial] = useState(showTutorialParam);
  const tutorialSteps = [{
    title: t('step1.title'),
    text: t('step1.text')
  }, {
    selector: '#coins',
    title: t('step2.title'),
    text: t('step2.text')
  }, {
    selector: '#langswitcher',
    title: t('step3.title'),
    text: t('step3.text')
  }, {
    selector: '#topGirls',
    title: t('step4.title'),
    text: t('step4.text')
  }, {
    selector: '#home',
    title: t('step5.title'),
    text: t('step5.text')
  }, {
    selector: '#profile',
    title: t('step6.title'),
    text: t('step6.text')
  }, {
    selector: '#coinsTopUp',
    title: t('step7.title'),
    text: t('step7.text')
  }];
  useEffect(() => {
    if (redirectToGirl && redirectToGirl.length > 0) {
      router.prefetch('/girl/' + redirectToGirl);
    }
    if (showTutorialParam) {
      setShowTutorial(true);
    }
    Sentry.setUser({
      email: user?.email,
      username: user?.username,
      id: user?.id
    });
  }, [searchParams]);
  return <>
            <QueryProvider data-sentry-element="QueryProvider" data-sentry-source-file="Providers.tsx">
                {showTutorial && <>
                        <Onboarding onComplete={() => {
          setShowTutorial(false);
          if (redirectToGirl && redirectToGirl.length > 0) {
            router.push('/girl/' + redirectToGirl);
          } else {
            router.replace('/home');
          }
        }} steps={tutorialSteps} />
                    </>}
                {children}
                <Toaster data-sentry-element="Toaster" data-sentry-source-file="Providers.tsx" />
            </QueryProvider>
        </>;
};
export default Providers;