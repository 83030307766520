'use client';

import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '@/lib/utils';
import { useTranslations } from 'next-intl';
interface OnboardingStep {
  title: string;
  text: string;
  selector?: string;
}
interface OnboardingProps {
  steps: OnboardingStep[];
  onComplete: () => void;
}
const Onboarding: React.FC<OnboardingProps> = ({
  steps,
  onComplete
}) => {
  const t = useTranslations('tutorial');
  const [currentStep, setCurrentStep] = useState(0);
  const [highlightedElement, setHighlightedElement] = useState<HTMLElement | null>(null);
  const [modalPosition, setModalPosition] = useState<{
    top: number | string;
    left: number | string;
  }>({
    top: '50%',
    left: '50%'
  });
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const currentSelector = steps[currentStep].selector;
    if (currentSelector) {
      const element = document.querySelector(currentSelector) as HTMLElement;
      if (element) {
        setHighlightedElement(element);
        positionModal(element);
      }
    } else {
      setHighlightedElement(null);
      setModalPosition({
        top: 'none',
        left: 'none'
      });
    }
  }, [currentStep, steps]);
  const positionModal = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const modalRect = modalRef.current?.getBoundingClientRect();
    if (!modalRect) return;
    let top = rect.bottom + 20;
    let left = rect.left + rect.width / 2;
    const isBottomElement = rect.bottom > window.innerHeight - 100;
    if (isBottomElement) {
      top = rect.top - modalRect.height - 20;
    }
    top = Math.max(20, Math.min(top, window.innerHeight - modalRect.height - 20));
    left = Math.max(modalRect.width / 2 + 20, Math.min(left, window.innerWidth - modalRect.width / 2 - 20));
    if (top + modalRect.height > window.innerHeight) {
      top = window.innerHeight - modalRect.height - 20;
    }
    if (left + modalRect.width > window.innerWidth) {
      left = window.innerWidth - modalRect.width - 20;
    }
    setModalPosition({
      top,
      left
    });
  };
  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onComplete();
    }
  };
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="Onboarding" data-sentry-source-file="Onboarding.tsx">
            <motion.div initial={{
      opacity: 0
    }} animate={{
      opacity: 1
    }} exit={{
      opacity: 0
    }} transition={{
      duration: 0.5
    }} className="fixed inset-0 z-[9999] flex items-center justify-center" data-sentry-element="unknown" data-sentry-source-file="Onboarding.tsx">
                {!highlightedElement && <motion.div initial={{
        opacity: 0
      }} animate={{
        opacity: 0.5
      }} exit={{
        opacity: 0
      }} transition={{
        duration: 0.3
      }} className="absolute inset-0 bg-black" />}
                {highlightedElement && <motion.div initial={{
        opacity: 0,
        scale: 0.9
      }} animate={{
        opacity: 1,
        scale: 1,
        top: highlightedElement.getBoundingClientRect().top - 10,
        left: highlightedElement.getBoundingClientRect().left - 10,
        width: highlightedElement.offsetWidth + 20,
        height: highlightedElement.offsetHeight + 20
      }} exit={{
        opacity: 0,
        scale: 0.9
      }} transition={{
        duration: 0.3
      }} className="absolute z-[10000]">
                        <motion.div initial={{
          opacity: 0
        }} animate={{
          opacity: 1
        }} exit={{
          opacity: 0
        }} transition={{
          duration: 0.3,
          delay: 0.1
        }} className="absolute inset-0 rounded-lg border-4 border-blue-500 shadow-[0_0_0_9999px_rgba(0,0,0,0.5)]" />
                    </motion.div>}

                <motion.div ref={modalRef} initial={{
        scale: 0.9,
        opacity: 0
      }} animate={{
        scale: 1,
        opacity: 1,
        top: modalPosition.top,
        left: modalPosition.left,
        x: !highlightedElement ? '-50%' : 0,
        y: !highlightedElement ? '-50%' : 0
      }} exit={{
        scale: 0.9,
        opacity: 0
      }} transition={{
        duration: 0.3
      }} className={cn('absolute z-[10001] w-[90%] max-w-2xl rounded-lg bg-white p-6 shadow-xl')} style={{
        top: modalPosition.top,
        left: modalPosition.left
      }} data-sentry-element="unknown" data-sentry-source-file="Onboarding.tsx">
                    <motion.h2 initial={{
          y: -20,
          opacity: 0
        }} animate={{
          y: 0,
          opacity: 1
        }} transition={{
          duration: 0.3,
          delay: 0.1
        }} className="mb-4 text-2xl font-bold text-blue-600" data-sentry-element="unknown" data-sentry-source-file="Onboarding.tsx">
                        {steps[currentStep].title}
                    </motion.h2>
                    <motion.p initial={{
          y: -10,
          opacity: 0
        }} animate={{
          y: 0,
          opacity: 1
        }} transition={{
          duration: 0.3,
          delay: 0.2
        }} className="mb-6 text-gray-700" data-sentry-element="unknown" data-sentry-source-file="Onboarding.tsx">
                        <div dangerouslySetInnerHTML={{
            __html: steps[currentStep].text
          }} />
                    </motion.p>
                    <motion.div initial={{
          y: 10,
          opacity: 0
        }} animate={{
          y: 0,
          opacity: 1
        }} transition={{
          duration: 0.3,
          delay: 0.3
        }} className="relative flex flex-col items-center justify-center gap-2" data-sentry-element="unknown" data-sentry-source-file="Onboarding.tsx">
                        <span className="absolute bottom-3 right-3 text-sm text-gray-500">
                            Krok {currentStep + 1} z {steps.length}
                        </span>
                        <motion.button whileHover={{
            scale: 1.05
          }} whileTap={{
            scale: 0.95
          }} onClick={handleNext} className={cn('rounded bg-blue-500 px-6 py-2 text-white transition-colors hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2')} data-sentry-element="unknown" data-sentry-source-file="Onboarding.tsx">
                            {currentStep === 0 ? t('buttons.enter') : currentStep < steps.length - 1 ? t('buttons.next') : t('buttons.complete')}
                        </motion.button>
                    </motion.div>
                </motion.div>
            </motion.div>
        </AnimatePresence>;
};
export default Onboarding;