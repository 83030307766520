'use client';

import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Upload, Loader, Check, Plus } from 'lucide-react';
type AnimatedUploadButtonProps = {
  onClick: () => void;
  isUploading: boolean;
  disabled?: boolean;
};
const AnimatedUploadButton = ({
  onClick,
  isUploading,
  disabled
}: AnimatedUploadButtonProps) => {
  const [status, setStatus] = useState<'idle' | 'uploading' | 'completed'>(isUploading ? 'uploading' : 'idle');
  useEffect(() => {
    if (isUploading) {
      setStatus('uploading');
    }
    if (!isUploading) {
      setStatus('completed');
      setTimeout(() => {
        setStatus('idle');
      }, 1500);
    }
  }, [isUploading]);
  const buttonVariants = {
    idle: {
      scale: 1,
      backgroundColor: 'hsl(var(--primary))'
    },
    hover: {
      scale: 1.1
    },
    pressed: {
      scale: 0.95
    },
    uploading: {
      scale: 1,
      backgroundColor: 'orange'
    },
    completed: {
      scale: 1,
      backgroundColor: 'green'
    }
  };
  const iconVariants = {
    idle: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2
      }
    },
    hover: {
      y: -2,
      transition: {
        duration: 0.2
      }
    },
    uploading: {
      opacity: 0,
      scale: 0,
      transition: {
        duration: 0.2
      }
    },
    completed: {
      opacity: 0,
      scale: 0,
      transition: {
        duration: 0.2
      }
    }
  };
  const loaderVariants = {
    idle: {
      opacity: 0,
      scale: 0,
      transition: {
        duration: 0.2
      }
    },
    uploading: {
      opacity: 1,
      scale: 1,
      rotate: 360,
      transition: {
        opacity: {
          duration: 0.2
        },
        scale: {
          duration: 0.2
        },
        rotate: {
          duration: 1,
          repeat: Infinity,
          ease: 'linear'
        }
      }
    },
    completed: {
      opacity: 0,
      scale: 0,
      transition: {
        duration: 0.2
      }
    }
  };
  const checkmarkVariants = {
    idle: {
      opacity: 0,
      scale: 0,
      pathLength: 0,
      transition: {
        duration: 0.2
      }
    },
    completed: {
      opacity: 1,
      scale: 1,
      pathLength: 1,
      transition: {
        opacity: {
          duration: 0.2
        },
        scale: {
          duration: 0.2,
          delay: 0.2
        },
        pathLength: {
          duration: 0.2
        }
      }
    }
  };
  const handleClick = () => {
    onClick();
  };
  return <motion.button className="flex h-12 w-12 items-center justify-center rounded-full bg-primary focus:outline-none" variants={buttonVariants} initial="idle" animate={status} whileHover="hover" whileTap="pressed" onClick={handleClick} disabled={status === 'uploading'} data-sentry-element="unknown" data-sentry-component="AnimatedUploadButton" data-sentry-source-file="AnimatedUploadButton.tsx">
            <motion.div variants={iconVariants} className="absolute" data-sentry-element="unknown" data-sentry-source-file="AnimatedUploadButton.tsx">
                <Plus className="text-white" size={24} data-sentry-element="Plus" data-sentry-source-file="AnimatedUploadButton.tsx" />
            </motion.div>
            <motion.div variants={loaderVariants} className="absolute" data-sentry-element="unknown" data-sentry-source-file="AnimatedUploadButton.tsx">
                <Loader className="text-white" size={24} data-sentry-element="Loader" data-sentry-source-file="AnimatedUploadButton.tsx" />
            </motion.div>
            <motion.div variants={checkmarkVariants} className="absolute" data-sentry-element="unknown" data-sentry-source-file="AnimatedUploadButton.tsx">
                <Check className="text-white" size={24} data-sentry-element="Check" data-sentry-source-file="AnimatedUploadButton.tsx" />
            </motion.div>
        </motion.button>;
};
export default AnimatedUploadButton;