'use client';

import UploadContentV2 from '@/app/[locale]/(private)/(creator)/(components)/UploadContentV2';
import { Role, User } from '@/db/schema';
import { pb, PbChat, PbGlobalChat, PbNotification, subscribe, subscriptions, unsubscribe } from '@/lib/pb';
import { checkUserRole, cn, getProfilePicture } from '@/lib/utils';
import { useChatStore, useNotifications } from '@/stores/chat';
import { useOznameni } from '@/stores/notifications.store';
import { useOnlineUsers } from '@/stores/onlineUsers.store';
import { useTopUpOpen } from '@/stores/topup.open.store';
import { useQueryClient } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { FaExclamation } from 'react-icons/fa';
import { FiImage, FiMessageCircle, FiPlus, FiVideo } from 'react-icons/fi';
import { IoChatbubbles, IoChatbubblesOutline, IoHome, IoHomeOutline, IoNotifications, IoNotificationsOutline } from 'react-icons/io5';
import AnimatedUploadButton from '../AnimatedUploadButton';
import TopUpDialog from '../TopUpDialog';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { BButton } from '../ui/button';
import { useToast } from '../ui/use-toast';
import './BottomNav.css';
import ProfileDrawer from './ProfileDrawer';
const BottomNav = ({
  user
}: {
  user: User;
}) => {
  const queryClient = useQueryClient();
  const {
    open: topUpOpen,
    setOpen: setTopUpOpen
  } = useTopUpOpen();
  const {
    toast
  } = useToast();
  const pathname = usePathname();
  const {
    addUnreadChat,
    unreadChats,
    setUnreadGChat,
    unreadGlobal
  } = useNotifications();
  const {
    onlineUsers
  } = useOnlineUsers();
  const notifications = useOznameni();
  const oznameni = useOznameni();
  const {
    add,
    updateChat
  } = useChatStore();
  const constructMessage = (message: string) => {
    return <div className="flex items-center gap-1" data-sentry-component="constructMessage" data-sentry-source-file="BottomNav.tsx">
                {message.split('_')[0] === 'IMG' ? <FiImage /> : message.split('_')[0] === 'VID' ? <FiVideo /> : <FiMessageCircle />}
                {message.split('_')[1]}
            </div>;
  };
  useEffect(() => {
    const checkUnReadGlobal = async (chatName: string, userId: string) => {
      const chatInfo = await pb.collection<PbGlobalChat>('globalChats').getFirstListItem(`chatName="${chatName}"`);
      const isUnread = chatInfo.readBy.includes(userId) ? false : true;
      setUnreadGChat(isUnread);
    };
    checkUnReadGlobal(user.role.includes(Role.Creator) ? 'girlsChat' : 'usersChat', user.id);
    subscribe<PbGlobalChat>('globalChats', e => {
      if (!window.location.pathname.includes(e.record.id)) {
        console.log(e);
        if (e.action === 'update') {
          if (e.record.chatName === 'girlsChat' && user.role.includes(Role.Creator)) {
            if (!e.record.readBy.some(readById => readById === user.id)) {
              setUnreadGChat(true);
              queryClient.invalidateQueries({
                queryKey: ['globalChats']
              });
              toast({
                title: `Nové zprávy v: ${e.record.displayName}`,
                description: e.record.lastMessage.split('_')[1],
                variant: 'success',
                duration: 3000
              });
            }
          } else if (e.record.chatName === 'usersChat' && user.role.includes(Role.User)) {
            if (!e.record.readBy.some(readById => readById === user.id)) {
              setUnreadGChat(true);
              queryClient.invalidateQueries({
                queryKey: ['globalChats']
              });
              toast({
                title: `Nové zprávy v: ${e.record.displayName}`,
                description: e.record.lastMessage.split('_')[1],
                variant: 'success',
                duration: 3000
              });
            }
          }
        }
      }
    });
    subscribe<PbChat>('chats', e => {
      if (!window.location.pathname.includes(e.record.id)) {
        if (e.action === 'update') {
          const lastMessageChanged = updateChat(e.record);
          if (lastMessageChanged) {
            addUnreadChat(e.record.id);
            if (e.record.lastMessage.split('_')[2] !== user.username) {
              toast({
                title: `Nová zpráva od: ${e.record.lastMessage.split('_')[2]}`,
                description: constructMessage(e.record.lastMessage),
                variant: 'success',
                duration: 3000
              });
            }
          }
        } else if (e.action === 'create') {
          addUnreadChat(e.record.id);
          add([e.record]);
        }
      }
      if (e.action === 'update') {
        queryClient.invalidateQueries({
          queryKey: ['chatInfo', e.record.id]
        });
      }
    }, {
      filter: `members~'${user.id}'`
    });
    subscribe<PbNotification>('notifications', e => {
      if (e.action === 'create') {
        queryClient.invalidateQueries({
          queryKey: ['notifications']
        });
        notifications.addOne();
        toast({
          title: `${e.record.title}`,
          description: e.record.text,
          variant: 'success',
          duration: 3000
        });
      }
    }, {
      filter: `toUser="${user.id}"`
    });
    return () => {
      if (subscriptions.has('globalChats')) unsubscribe('globalChats');
      if (subscriptions.has('notifications')) unsubscribe('notifications');
      if (subscriptions.has('chats')) unsubscribe('chats');
    };
  }, []);
  const links = useMemo(() => [{
    name: 'home',
    href: user.role.includes(Role.Creator) && !user.role.includes(Role.Admin) ? '/profile' : '/home',
    Icon: <IoHomeOutline size={30} />,
    ActiveIcon: <IoHome size={30} />,
    label: 'Domů'
  }, {
    name: 'notifications',
    href: '/notifications',
    Icon: <IoNotificationsOutline size={30} />,
    ActiveIcon: <IoNotifications size={30} />,
    label: 'Notifikace'
  }, {
    name: 'topup',
    action: !user.role.includes(Role.Creator) ? () => setTopUpOpen(true) : () => {
      console.log('opening');
      setUploadOpen(true);
    },
    Icon: <FiPlus size={30} />,
    ActiveIcon: <FiPlus size={30} />,
    label: !user.role.includes(Role.Creator) ? 'Nabít Kredity' : 'Přidat Příspěvek'
  }, {
    name: 'chats',
    href: '/chats',
    Icon: <IoChatbubblesOutline size={30} />,
    ActiveIcon: <IoChatbubbles size={30} />,
    label: 'Chaty'
  }, {
    name: 'profile',
    action: () => setProfileOpen(prev => !prev),
    Icon: <Avatar className="avatar h-[30px] w-[30px]">
                        <AvatarImage src={getProfilePicture(user.username, user.image) ?? ''} />
                        <AvatarFallback>
                            <img src={`https://api.dicebear.com/9.x/thumbs/svg?seed=${user.username}`} alt={user.username} />
                        </AvatarFallback>
                    </Avatar>,
    label: 'Profil'
  }], []);
  const [profileOpen, setProfileOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  useEffect(() => {
    setProfileOpen(false);
    setUploadOpen(false);
    setTopUpOpen(false);
  }, [pathname]);
  return <>
            <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="BottomNav.tsx">{uploadOpen && <UploadContentV2 onClose={() => setUploadOpen(false)} />}</AnimatePresence>
            <div className={cn('fixed bottom-0 z-40 h-20 w-full', pathname.includes('/chats/') && 'hidden')}>
                <TopUpDialog isOpen={topUpOpen} onClose={open => setTopUpOpen(open)} data-sentry-element="TopUpDialog" data-sentry-source-file="BottomNav.tsx" />
                <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="BottomNav.tsx">
                    {/* {uploadOpen && <UploadContent onClose={open => setUploadOpen(open)} />} */}
                    {profileOpen && <ProfileDrawer user={user} isOpen={profileOpen} onClose={() => setProfileOpen(false)} />}
                </AnimatePresence>
                <nav className="inset-x-0 z-40 flex h-20 items-center justify-around bg-background px-4 py-2" style={{
        boxShadow: '0px -3px 10px 0px hsl(0,0%,0%)'
      }}>
                    {links.map(({
          Icon,
          ActiveIcon,
          ...link
        }) => {
          if (link.name === 'topup') {
            return <Fragment key={link.name}>
                                    {checkUserRole(user, [Role.Creator]) ? <AnimatedUploadButton key={link.name} onClick={link.action!} isUploading={false} /> :
              // <AnimatedUploadButton key={link.name} onClick={() => router.push('/upload')} isUploading={!ffmpeg?.loaded || isUploading} />
              // <AnimatedUploadButton key={link.name} onClick={link.action!} isUploading={!ffmpeg?.loaded || isUploading} />
              <BButton id="coinsTopUp" key={link.name} disabled={checkUserRole(user, [Role.Creator])} onClick={link.action} size="icon" className="relative flex flex-col items-center justify-center gap-2 text-xs">
                                            {Icon}
                                        </BButton>}
                                </Fragment>;
          }
          if (link.name === 'profile') {
            return <div className="relative" key={link.name} onClick={link.action} id="profile">
                                    <Avatar className="avatar h-[30px] w-[30px]">
                                        <AvatarImage src={getProfilePicture(user.username, user.image) ?? ''} />
                                    </Avatar>
                                    <span className={cn('absolute bottom-0 right-0 size-3 rounded-full', {
                'bg-red-500': !onlineUsers.some(ou => ou.id === user.id),
                'bg-green-500': onlineUsers.some(ou => ou.id === user.id)
              })} />
                                </div>;
          }
          return <Link id={link.name} key={link.name} href={link.href ?? ''} onClick={link.action} className="relative flex flex-col items-center justify-center gap-2 text-xs">
                                {pathname === link.href ? ActiveIcon : Icon}
                                {unreadChats.count > 0 && link.name === 'chats' && <span className="absolute -right-2 -top-2 flex h-5 w-5 items-center justify-center rounded-full bg-primary">{unreadChats.count}</span>}
                                {unreadGlobal && link.name === 'chats' && <span className="absolute -left-2 -top-2 flex h-5 w-5 items-center justify-center rounded-full bg-primary">
                                        <FaExclamation className="text-white" />
                                    </span>}
                                {oznameni.unreadCount > 0 && link.name === 'notifications' && <span className="absolute -right-2 -top-2 flex h-5 w-5 items-center justify-center rounded-full bg-primary font-bold text-white">
                                        {oznameni.unreadCount}
                                    </span>}
                            </Link>;
        })}
                </nav>
            </div>
        </>;
};
export default BottomNav;