import { create } from 'zustand';
import { differenceInMinutes } from 'date-fns';

type OnlineState = {
    onlineUsers: { id: string; lastSeen: string }[];
    isUserOnline: (id: string) => boolean;
    addOnlineUser: (id: string, lastSeen: string) => void;
    removeOnlineUser: (id: string) => void;
    setOnlineUsers: (users: { id: string; lastSeen: string }[]) => void;
    getUserStatusColor: (id: string) => 'green' | 'orange' | 'red';
};

export const useOnlineUsers = create<OnlineState>((set, get) => ({
    onlineUsers: [],
    isUserOnline: (id: string) => {
        return get().onlineUsers.some(user => user.id === id);
    },
    getUserStatusColor: (id: string) => {
        const { onlineUsers } = get();
        const user = onlineUsers.find(u => u.id === id);
        if (user) {
            const difference = differenceInMinutes(new Date(), user.lastSeen);
            if (difference <= 5) {
                return 'green';
            } else if (difference < 60) {
                return 'orange';
            }
        }
        return 'red';
    },
    addOnlineUser: (id, lastSeen) => set(state => ({ onlineUsers: [...state.onlineUsers.filter(ou => ou.id !== id), { id, lastSeen }] })),
    removeOnlineUser: id => set(state => ({ onlineUsers: state.onlineUsers.filter(user => user.id !== id) })),
    setOnlineUsers: users => set({ onlineUsers: users })
}));
